// src/components/Dashboard.js
import React from 'react';

const Dashboard = () => {
  return (
    <div className="container mt-5">
      <div className="image-container">
        <img src="/dashboard-PM.svg" alt="Dashboard" className="image" />
      </div>
    </div>
  );
};

export default Dashboard;