import React, { useState, useEffect } from 'react';
import 'bulma/css/bulma.min.css';
// import '@fortawesome/fontawesome-free/css/all.min.css';
import Papa from 'papaparse';
import Pagination from './Pagination';

const FileList = () => {
    const [files, setFiles] = useState([]);
	const [selectedFile, setSelectedFile] = useState(null);

    const currentPage = 1; // Static current page for fake pagination
    const totalPages = 40; // Set total pages to 50 for fake pagination

    useEffect(() => {
        // Fetch and parse the CSV file
        Papa.parse('/files.csv', {
            download: true,
            header: true,
            complete: (results) => {
                setFiles(results.data);
            }
        });
    }, []);


	const handleFileClick = (file) => {
		setSelectedFile(file);
	};

	return (
		<div className="container mt-5">
			<h1 className="title">Change Order Files</h1>
            <div className="box">
                <table className="table is-fullwidth">
                    <thead>
                        <tr>
                            <th>File Name</th>
                            <th>File Size</th>
                            <th>Last Modified Date</th>
                        </tr>
                    </thead>
                    <tbody>
                        {files.map((file, index) => (
                            <tr
                                key={index}
                                className={selectedFile === file ? 'is-selected' : ''}
                                onClick={() => handleFileClick(file)}
                                style={{ cursor: 'pointer' }}
                            >
                                <td>{file.file_names}</td>
                                <td>{file.file_size}</td>
                                <td>{file.last_modified_date}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
			</div>
            <Pagination
                currentPage={currentPage}
                totalPages={totalPages}
            />
		</div>
	);
};

export default FileList;