import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';

const Navbar = () => {
	const navigate = useNavigate();
	const [isActive, setIsActive] = useState(false);

	const toggleNavbar = () => {
		setIsActive(!isActive);
	};

	return (
		<nav className="navbar is-primary" role="navigation" aria-label="main navigation">
			<div className="navbar-brand">
				<Link className="navbar-item" to="/demo/">
					<img src="/logo.png" alt="Logo" width="28" height="28" />
				</Link>
				<a
					role="button"
					className={`navbar-burger burger ${isActive ? 'is-active' : ''}`}
					aria-label="menu"
					aria-expanded={isActive ? 'true' : 'false'}
					data-target="navbarBasicExample"
					onClick={toggleNavbar}
				>
					<span aria-hidden="true"></span>
					<span aria-hidden="true"></span>
					<span aria-hidden="true"></span>
				</a>
			</div>

			<div id="navbarBasicExample" className={`navbar-menu ${isActive ? 'is-active' : ''}`}>
				<div className="navbar-start">
					<Link className="navbar-item" to="/demo/files">
						Change Order Files
					</Link>
					<Link className="navbar-item" to="/demo/cos">
						Change Orders
					</Link>
					<Link className="navbar-item" to="/demo/dashboard">
						Dashboard
					</Link>
					<Link className="navbar-item" to="/demo/">
						Upload File
					</Link>
					<a className="navbar-item" onClick={() => navigate('/demo/results')}>
						Analysis Results
					</a>
				</div>
			</div>
		</nav>
	);
};

export default Navbar;